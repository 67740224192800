import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Posts from "../components/posts"

const BlogIndex = ({ data, location }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title="lett.dev">
       <section className="module__intro">
        <h2>Hello, World!</h2>
        <p>Welcome to my web log.<br/>Have a good day and enjoy the code!</p>
      </section>
      {/* <section className="module__recent">
        <h2>Recent Posts</h2>
        <Posts data={posts} />
        <Link to="/blog">Browse all posts	→</Link>
      </section> */}
    </Layout>
  )
}

export default BlogIndex

export const Head = () => <Seo title="Home" />

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 5) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          title
        }
      }
    }
  }
`
