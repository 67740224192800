import * as React from "react"
import { Link } from "gatsby"

const Layout = ({ location, title, children, isBlogPost, noHeading }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <div className="page-title">
        <h1 className="main-heading">{title}</h1>
      </div>
    )
  } else if (isBlogPost) {
    header = (
      <div className="page-title">
        <Link to="/" title="Back to home">lett.dev</Link>/
        <Link to="/blog" title="Back to archive">blog</Link>/
      </div>
    )
  } else {
    if (noHeading) {
      header = (
      <div className="page-title">
        <Link to="/" title="Back to home">lett.dev</Link>/
        <h1 className="main-heading">{title}</h1>
      </div>
      )
    } else {
      header = (
        <div className="page-title">
          <Link to="/" title="Back to home">lett.dev</Link>/
        </div>
        )
    }
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <header className="global-header">{header}</header>
      <main>{children}</main>
      <footer className="global-footer">
        © {new Date().getFullYear()}, lett.dev
      </footer>
    </div>
  )
}

export default Layout
