import * as React from "react"
import { Link } from "gatsby"

const Posts = ({ data, groupedByYear }) => {

  if (data.length === 0) {
    return (
      <p>
        No blog posts found.
      </p>
    )
  }

  const postsByYear = () => {
    let yearData = {}
    data.forEach(post => {
      var year = post.frontmatter.date.split(', ')[1]
      yearData[year] = [...(yearData[year] || []), post]
    })
    return yearData
  }

  if (groupedByYear) {
    const yearData = postsByYear()
    const years = Object.keys(yearData).reverse()
    return (
      <>
        {years.map(year => (
          <section key={year} className="year-archive">
            <h2>{year}</h2>
            <ol className="post-list">
              {yearData[year].map(post => {
                const title = post.frontmatter.title || post.fields.slug
                return (
                  <li key={post.fields.slug}>
                    <article
                      className="post-list-item"
                      itemScope
                      itemType="http://schema.org/Article"
                    >
                      <Link to={post.fields.slug} itemProp="url">
                        <small>{post.frontmatter.date.split(', ')[0]}</small>
                        <span itemProp="headline">{title}</span>
                      </Link>
                    </article>
                  </li>
                )
              })}
            </ol>
          </section>
        ))}
      </>
    )
  }

  return (
    <ol className="post-list">
      {data.map(post => {
        const title = post.frontmatter.title || post.fields.slug

        return (
          <li key={post.fields.slug}>
            <article
              className="post-list-item"
              itemScope
              itemType="http://schema.org/Article"
            >
              <Link to={post.fields.slug} itemProp="url">
                <small>{post.frontmatter.date}</small>
                <span itemProp="headline">{title}</span>
              </Link>
            </article>
          </li>
        )
      })}
    </ol>
  )
}

export default Posts